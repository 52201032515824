/**
 * Email validation rule check if the given data is formatted as an email
 * @param {String} name Name of the field to be displayed in the error message (es: 'Other email')
 * @returns Returns true if the field is correct, an error message otherwise
 */
export const emailRule = (name) => {
  return [
    (v) => {
      let emailRegex = /.+@.*\..+/gm;
      let result = v?.toString().match(emailRegex)?.length > 0 || false;
      return result || `Indirizzo email non valido per il campo ${name}`;
    },
  ];
};

/**
 * Required validation rule check if the given data is filled
 * @param {String} name Name of the field to be displayed in the error message (es: 'Surname')
 * @returns Returns true if the field is correct, an error message otherwise
 */
export const requiredRule = (name) => {
  return [
    (v) => {
      return !!v || `Il campo ${name} è obbligatorio`;
    },
  ];
};
