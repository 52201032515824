import { mapState } from "@/import";
export default {
  computed: {
    ...mapState({
      currentUser: (state) => state?.loggedUser,
    }),
    currentUserRole() {
      return this.currentUser ? this.currentUser["custom:role"] : null;
    },
    currentUserIdSgAdmin() {
      return this.currentUser ? this.currentUser["custom:idSgAdmin"] : null;
    },
    currentUserSportType() {
      return this.currentUser ? this.currentUser["custom:sportType"] : null;
    },
    displayBP() {
      return this.$vuetify.breakpoint.name;
    },
    isUserAdmin() {
      return this.currentUserRole === "admin";
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setBanner(params = {}) {
      let { color = "primary", text = "snackbarText", isOpen = true } = params;
      this.$store.dispatch("setSnackbar", {
        isSnackbarOpen: isOpen,
        snackbarColor: color,
        snackbarText: text,
      });
    },
  },
};
