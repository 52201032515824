<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="isSnackbarOpen" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{}">
          <v-icon small>mdi-window-close</v-icon>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { globalMixin, mapState, moment, Vue } from "@/import";
import PortalVue from "portal-vue";
require("moment/locale/it");

Vue.filter("date", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("dateFormat", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("dateMonth", function (value) {
  if (value) {
    let res = moment(String(value)).format("MMMM YYYY");
    res = res.slice(0, 1).toLocaleUpperCase() + res.slice(1);
    return res;
  }
});

Vue.use(PortalVue);
Vue.mixin(globalMixin); // mixin registrato globalmente
export default {
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      snackbarColor: (state) => state?.snackbarOptions?.snackbarColor,
      snackbarText: (state) => state?.snackbarOptions?.snackbarText,
    }),
    isSnackbarOpen: {
      get: function () {
        return this?.$store?.state?.snackbarOptions?.isSnackbarOpen;
      },
      set: function (value) {
        this.setBanner({ isOpen: value });
      },
    },
  },
};
</script>

<style lang="scss">
body::-webkit-scrollbar {
  display: none;
}

.v-card__title.headline {
  word-break: break-word;
}
</style>
