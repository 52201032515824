<template>
  <div class="home-view-container">
    <v-app-bar app color="primary" dark :height="50">
      Gestionale sportivo a cura di Alessandro Bianciardi & Davide Pavei
      <v-spacer></v-spacer>
      <v-avatar
        color="grey lighten-2 pointer"
        :size="40"
        @click="redirectToAdmin"
      >
        <v-icon dark> mdi-account </v-icon>
      </v-avatar>
    </v-app-bar>

    <div class="img-header-container">
      <v-img src="@/assets/sport-header.jpg" class="header-bg"></v-img>
      <h1 class="header-text">
        Gestionale dedicato alle polisportive per le iscrizioni online
      </h1>
    </div>
    <!--v-btn @click="goToAdmin">Vai a BO (temporaneo)</!--v-btn -->
    <RegistrationForm v-if="!isSaved" v-model="newUser" @save="saveNewUser" />

    <div v-else class="sent-data-container d-flex flex-column align-center">
      <v-card class="sent-card my-10">
        <v-card-title>Dati inviati con successo</v-card-title>
        <v-card-text
          >Abbiamo ricevuto correttamente la tua domanda di iscrizione, nei
          prossimi giorni riceverai comunicazione via mail per il pagamento e i
          documenti necessari</v-card-text
        >

        <div class="d-flex flex-column align-center">
          <v-btn @click="resetPage" class="primary mb-4"
            >Nuova iscrizione</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { USER_TABLE } from "@/lib/constant";

import { guid, myAxios, RegistrationForm } from "@/import";

export default {
  name: "HomeView",
  components: {
    RegistrationForm,
  },
  data() {
    return {
      idSgAdmins: null,
      isSaved: false,
      newUser: {},
    };
  },
  methods: {
    goToAdmin() {
      this.$router.push({ name: "adminHome" });
    },
    saveNewUser() {
      this.newUser = {
        ...this.newUser,
        id: guid(),
        idSgAdmins: this.newUser.idSgAdmins,
        creationDate: new Date(),
      };

      myAxios.post(USER_TABLE, this.newUser).then(() => {
        this.isSaved = true;
        this.setBanner({
          color: "success",
          text: "Dati inviati con successo",
        });
      });
    },
    redirectToAdmin() {
      this.$router.push({ name: "admin" });
    },
    resetPage() {
      this.isSaved = false;
      this.newUser = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.img-header-container {
  position: relative;
  height: calc(100vh - 50px);

  .header-bg {
    position: absolute;
    width: 100%;
  }

  .header-text {
    height: calc(100vh - 50px);
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    text-align: center;
  }
}

.header-bg {
  height: calc(100vh - 50px);
}

.home-view-container {
  height: calc(100vh - 50px);
  overflow: auto;
}

.sent-card {
  width: 100%;
  max-width: 800px;
}

.pointer {
  cursor: pointer;
}
</style>
