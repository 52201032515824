export const AthleteDataCard = () =>
  import(
    /* webpackChunkName: "AthleteDataCard" */ "@/components/Public/Registration/AthleteDataCard"
  );
export const AutoCompleteGroup = () =>
  import(
    /* webpackChunkName: "AutoCompleteGroup" */ "@/components/Shared/AutoCompleteGroup"
  );
export const BirthDataCard = () =>
  import(
    /* webpackChunkName: "BirthDataCard" */ "@/components/Public/Registration/BirthDataCard"
  );
export const ClubLogo = () =>
  import(/* webpackChunkName: "ClubLogo" */ "@/components/Shared/ClubLogo");
export const ClubsAutoComplete = () =>
  import(
    /* webpackChunkName: "ClubsAutoComplete" */ "@/components/Shared/ClubsAutoComplete"
  );
export const ContactDataCard = () =>
  import(
    /* webpackChunkName: "ContactDataCard" */ "@/components/Public/Registration/ContactDataCard"
  );
export const DataTable = () =>
  import(/* webpackChunkName: "dataTable" */ "@/components/Shared/DataTable");
export const DataTableHeader = () =>
  import(
    /* webpackChunkName: "dataTableHeader" */ "@/components/Shared/DataTableHeader"
  );
export const DatePicker = () =>
  import(/* webpackChunkName: "DatePicker" */ "@/components/Shared/DatePicker");
export const DiscountCard = () =>
  import(
    /* webpackChunkName: "DiscountCard" */ "@/components/Public/Registration/DiscountCard"
  );
export const Menu = () =>
  import(/* webpackChunkName: "Menu" */ "@/components/App/Menu");
export const MenuItem = () =>
  import(/* webpackChunkName: "MenuItem" */ "@/components/App/MenuItem");
export const Modal = () =>
  import(/* webpackChunkName: "Modal" */ "@/components/Shared/Modal");
export const MonthPicker = () =>
  import(
    /* webpackChunkName: "MonthPicker" */ "@/components/Shared/MonthPicker"
  );
export const Parent1DataCard = () =>
  import(
    /* webpackChunkName: "Parent1DataCard" */ "@/components/Public/Registration/Parent1DataCard"
  );
export const Parent2DataCard = () =>
  import(
    /* webpackChunkName: "Parent2DataCard" */ "@/components/Public/Registration/Parent2DataCard"
  );
export const PaymentLog = () =>
  import(/* webpackChunkName: "PaymentLog" */ "@/components/Admin/PaymentLog");
export const PaymentReceipt = () =>
  import(
    /* webpackChunkName: "PaymentReceipt" */ "@/components/Admin/PaymentReceipt"
  );
export const RegistrationForm = () =>
  import(
    /* webpackChunkName: "RegistrationForm" */ "@/components/Public/Registration/RegistrationForm"
  );
export const ResidenceDataCard = () =>
  import(
    /* webpackChunkName: "ResidenceDataCard" */ "@/components/Public/Registration/ResidenceDataCard"
  );
export const UserInstallmentPayments = () =>
  import(
    /* webpackChunkName: "UserInstallmentPayments" */ "@/components/Admin/UserInstallmentPayments"
  );
export const UserInstallmentPaymentOptions = () =>
  import(
    /* webpackChunkName: "UserInstallmentPaymentOptions" */ "@/components/Admin/UserInstallmentPaymentOptions"
  );
export const UserDetailLight = () =>
  import(
    /* webpackChunkName: "UserDetailLight" */ "@/components/Admin/UserDetailLight"
  );
export const TopBar = () =>
  import(/* webpackChunkName: "TopBar" */ "@/components/App/TopBar");
export const UserAvatar = () =>
  import(/* webpackChunkName: "UserAvatar" */ "@/components/Shared/UserAvatar");

import Vue from "vue";
import Vuex from "vuex";
import { mapGetters, mapState } from "vuex";
import globalMixin from "@/lib/mixin/globalMixin";
import { myAxios } from "@/lib/myAxios";
import { v4 as guid } from "uuid";
import moment from "moment";

import { emailRule, requiredRule } from "./lib/valildationRules";
export { emailRule, requiredRule };

export { Vue, Vuex, globalMixin, guid, mapGetters, mapState, moment, myAxios };
