import { Vue, Vuex } from "@/import";
import serviceStore from "./serviceStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedUser: {},
    snackbarOptions: {},
    userListOptions: {},
  },
  getters: {},
  mutations: {
    setStateObject(state, { key, value }) {
      Vue.set(state, key, value);
    },
    setSnackbarOptions(state, params) {
      Vue.set(state, "snackbarOptions", params);
    },
  },
  actions: {
    setSnackbar({ commit }, params) {
      commit("setSnackbarOptions", params);
    },
    setObject({ commit }, params) {
      commit("setStateObject", params);
    },
  },
  modules: {
    serviceStore,
  },
});
