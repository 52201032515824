export const API_URL_SG_DB_MANAGER = process.env.VUE_APP_DOMAIN_DB_MANAGER;

export const INVOICE_TABLE = "invoice";
export const USER_TABLE = "user";
export const SERVICE_TABLE = "service";
export const SPORTSCLUBS_TABLE = "admin";
export const PAYMENT_TABLE = "payment";
export const PAYMENT_LOG_TABLE = "logPayment";

export const NOT_SENT = "not-sent";
export const SENT = "sent";
export const PAYED = "payed";
export const INVOICED = "invoiced"; //fatturato

export const PAYMENT_ADDED = "payment-added";
export const PAYMENT_DELETED = "payment-deleted";
export const PAYMENT_INVOICED = "payment-invoiced";
export const PAYMENT_VERIFIED = "payment-verified";
export const MAIL_SENT = "mail-sent";
