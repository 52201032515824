import { Vue, Vuex } from "@/import";

Vue.use(Vuex);

const serviceStore = {
  namespaced: true,
  state: {
    idSgAdmin: null,
  },
  mutations: {
    setIdSgAdmin(state, value) {
      Vue.set(state, "idSgAdmin", value);
    },
  },
  actions: {
    setCurrentIdSgAdmin({ commit }, value) {
      commit("setIdSgAdmin", value);
    },
  },
};
export default serviceStore;
