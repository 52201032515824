import axios from "axios";
import { API_URL_SG_DB_MANAGER } from "@/lib/constant";

export const myAxios = {
  /**
   * Execute a axios get call
   * @param {String} model Name of the table to call
   * @param {Object} queryParams Query params of the get call
   * @returns Returns an Object with the data
   */
  get: (model, queryParams) => {
    let params = {
      ...queryParams,
      model,
    };
    return axios
      .get(API_URL_SG_DB_MANAGER, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  },

  /**
   * Execute a post axios call to save new data
   * @param {String} model Name of the table where data will be saved
   * @param {Object} params Object containing the data to be saved
   * @returns Returns an object with the saved data
   */
  post: (model, params) => {
    return axios
      .post(`${API_URL_SG_DB_MANAGER}?model=${model}`, params)
      .then(({ data }) => {
        return data.Item;
      });
  },

  /**
   * Execute a put axios call to update new data
   * @param {String} model Name of the table where data will be saved
   * @param {Object} params Object containing the data to be saved
   * @returns Returns an object with the saved data
   */
  put: (model, params) => {
    return axios
      .put(`${API_URL_SG_DB_MANAGER}?model=${model}`, params)
      .then(({ data }) => {
        return data.Item;
      });
  },

  /**
   * Execute a delete axios call to delete some data
   * @param {String} model Name of the table where data will be deleted
   * @param {Object} params Object containing the data to be deleted
   * @returns Returns an object with the deleted data
   */
  delete: (model, params) => {
    return axios
      .delete(API_URL_SG_DB_MANAGER, {
        params: {
          model,
        },
        data: params,
      })
      .then(({ data }) => {
        return data.Item;
      });
  },

  sendMail: (params) => {
    return axios.post(process.env.VUE_APP_MAIL_MANAGER, params);
  },

  createPdf: (params) => {
    return axios.post(process.env.VUE_APP_DOMAIN_PDF, params);
  },

  /**
   * Uploads file to the relative bucket
   * @param {String} bucket Bucket name where the file will be saved
   * @param {String} fileName Name of the file to be saved
   * @param {String} fileStream Binary data stream
   * @returns
   */
  uploadMedical: (params) => {
    return axios
      .post(process.env.VUE_APP_PDF, params, {
        responseType: "arraybuffer",
        responseEncoding: "binary",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
      .then((data) => {
        return data;
      });
  },

  downloadMedia: (params) => {
    return axios.post(process.env.VUE_APP_PDF, params).then((data) => {
      return data;
    });
  },
};
