import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: "adminHome" };
    },
    children: [
      {
        path: "new-account",
        name: "newAccount",
        component: () =>
          import(
            /* webpackChunkName: "newAccount" */ "../views/Admin/NewAccount.vue"
          ),
      },
      {
        path: "home",
        name: "adminHome",
        component: () =>
          import(/* webpackChunkName: "adminHome" */ "../views/Admin/Home.vue"),
      },
      {
        path: "registered-users",
        name: "registeredUsers",
        component: () =>
          import(
            /* webpackChunkName: "registeredUsers" */ "../views/Admin/RegisteredUsers.vue"
          ),
      },
      {
        path: "registered-user-detail/:id/:idSgAdmins?",
        name: "registeredUserDetail",
        component: () =>
          import(
            /* webpackChunkName: "registeredUserDetail" */ "../views/Admin/RegisteredUserDetail.vue"
          ),
      },
      {
        path: "registered-user-full-detail/:id/:idSgAdmins?",
        name: "registeredUserFullDetail",
        component: () =>
          import(
            /* webpackChunkName: "registeredUserFullDetail" */ "../views/Admin/RegisteredUserFullDetail.vue"
          ),
      },
      {
        path: "service-list/",
        name: "serviceList",
        component: () =>
          import(
            /* webpackChunkName: "serviceList" */ "../views/Admin/ServiceList.vue"
          ),
      },
      {
        path: "service-details/:id?/:idSgAdmins?",
        name: "serviceDetails",
        component: () =>
          import(
            /* webpackChunkName: "serviceDetails" */ "../views/Admin/ServiceDetails.vue"
          ),
      },
      {
        path: "sports-clubs-list",
        name: "sportsClubList",
        component: () =>
          import(
            /* webpackChunkName: "sportsClubList" */ "../views/Admin/SportsClubList.vue"
          ),
      },
      {
        path: "sports-clubs-details/:id?",
        name: "sportsClubDetails",
        component: () =>
          import(
            /* webpackChunkName: "sportsClubDetails" */ "../views/Admin/SportsClubDetails.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
